body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.brand-logo{
  display: inline;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #1890ff !important;
  text-decoration: underline !important;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.fill-screen{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}


.bg-primary-low{
  background-color: #e2f1fd;
}
.bg-black{
  background-color: #000000;
}
.bg-gradient{
  /* background-image: linear-gradient(130deg, #a6a4fd 0%, #0a1c48 92%); */
  background-image: -webkit-linear-gradient(320deg, #00c647 0%, #54e2a0 92%);
  background-image: linear-gradient(130deg, #00c647 0%, #54e2a0 92%);
}

.bg-primary-gradient {
  background-image: -webkit-linear-gradient(320deg, #00c647 0%, #54e2a0 92%);
  background-image: linear-gradient(130deg, #00c647 0%, #54e2a0 92%);
}

.form-container{
  /* border-left: 1px solid #cccccc; */
  background-color: #ffffff;
  padding: 20px;
}

.scroll-container{
  height: "100%";
  max-height: 400px;
  overflow-y: auto;
}

.note-scroll-container{
  max-height: 90px;
  overflow-y: auto;
}

body{
  font-size: 16px !important;
  color: #1a1a1a;
  line-height: 22px !important;
  font-family: 'Helvetica', sans-serif;
  background-color: #fff;
}

.box-shadow{
  box-shadow: 0px 4px 4px 0px rgba(218,218,218,0.67);
}

.shadow{
  /* 0 10px 50px rgba(0,0,0,0.3); */
  /* -webkit-box-shadow: 0px 4px 4px 0px rgba(218,218,218,0.67);
  -moz-box-shadow: 0px 4px 4px 0px rgba(218,218,218,0.67);
  box-shadow: 0px 4px 4px 0px rgba(218,218,218,0.67); */
  border: 1px solid #eaeaea;
}
.shadow-bordered{
  border: 1px solid #eaeaea;
  border-radius: 12px;
}

.profile-bg{
  background-color: #1e87e0;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(28%, #1e87e0), to(#0a3c7e));
  background-image: -webkit-linear-gradient(bottom, #1e87e0 28%, #0a3c7e 100%);
  background-image: linear-gradient(0deg, #1e87e0 28%, #0a3c7e 100%);
  padding-bottom: 16px;
  width:100%;
  border-radius:16px;
}

.btn-primary {
  font-weight: 600;
  background-color: #00b13e;
  border-color: #00d349;
  padding: 8px 32px;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary.disabled, .btn-primary:disabled {
  font-weight: 600;
  background-color: #00d349;
  border-color: #00b13e;
  padding: 8px 32px;
}



.btn-primary-outline{
  font-weight: 600;
  border-color: #00d349;
  color: #00d349;
  padding: 8px 32px;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1e87e0;
  border-color: #1e87e0;
}

.btn-remove{
  width:100px;
  padding:0;
}

.video-card{
  /* max-height: 424px !important; */
  /* width: 331px; */
  overflow: hidden;
}

.video-carousel-container{
  min-height: 540px;
  /* background-color: #fafafa; */
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 0px;
}

.responses-container{
  min-height: 600px;
  padding:20px;
}

.profile-img{
  width:100px;
  height: 100px;
}

.video-js{
  margin: auto;
  background-color: transparent;
}
.questionnaire-card{
  border-left: none !important;
  border-right: none !important;
  min-height: 320px;
}

.jobs-table tr:hover {
  background:#f1f1f1;
  cursor: pointer;
}

.thumbnail-card{
  /* display: inline-block; */
  /* float: left; */
  width: "100%";
  /* height: 160px; */
  margin-right: 8px;
  margin-bottom: 8px;
  /* padding: 8px 8px 0 0; */
}

.thumbnail-card img{
  max-width: 100%;
  display: block;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  border-radius:8px;
}

.thumbnail-title-container{
  width:100%;
  position: relative;
  top: 0;
}

.thumbnail-title{
  border-radius: 8px 8px 0 0;
  width:100%;
  position: absolute;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(5,5,5,0.88)), color-stop(50%, rgba(5,5,5,0.6)), to(rgba(252,249,252,0.01)));
  background: -webkit-linear-gradient(top, rgba(5,5,5,0.88) 0%, rgba(5,5,5,0.6) 50%, rgba(252,249,252,0.01) 100%);
  background: linear-gradient(to bottom, rgba(5,5,5,0.88) 0%, rgba(5,5,5,0.6) 50%, rgba(252,249,252,0.01) 100%);
  padding: 4px 8px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

i.far{
  font-size: 24px;
  padding: 0;
  color: "#0FB56E";
}

.share-button{
  float: left;
  margin: 0 15px;
}

.slider-card{
  width: 160px;
}

.relative{
  position: relative;
}
.slider-nav-container{
  display: block;
  position: absolute;
  top: 0px;
  right: 24px;
}

.btn-circle{
  padding: 0;
  background: none;
  margin: 6px;
  border: none;
}
.btn-circle i.fas{
  font-size: 15px;
  line-height: 10px;
  padding: 0;
  opacity: 0.6;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 12px;
}
.table td{
  vertical-align: middle;
}
.btn-circle i.fas.fa-angle-right{
  font-size: 24px;
  border:none;
  cursor: pointer;
  padding: 0;
  opacity: 0.6;
}

.mini-video-card{
  overflow: hidden;
  margin-bottom:16px;
}

.br-8{
  border-radius:8px;
}

.slick-arrow{
  display: none;
}

.video-title-container{
  width:100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(5,5,5,0.98)), color-stop(60%, rgba(5,5,5,0.7)), to(rgba(252,249,252,0.01)));
  background: -webkit-linear-gradient(top, rgba(5,5,5,0.98) 0%, rgba(5,5,5,0.7) 60%, rgba(252,249,252,0.01) 100%);
  background: linear-gradient(to bottom, rgba(5,5,5,0.98) 0%, rgba(5,5,5,0.7) 60%, rgba(252,249,252,0.01) 100%);
  position: absolute;
  top: 0;
  padding: 8px;
}

.video-title{
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}

.video-gradient{
  background: rgba(5,5,5,0.68);
  background: -webkit-gradient(linear,left top, left bottom,color-stop(28%, rgba(5,5,5,.88)),color-stop(88%, hsla(0,0%,96.5%,.13)),to(rgba(5,5,5,.00)));
  background: -webkit-linear-gradient(top,rgba(5,5,5,.88) 28%,hsla(0,0%,96.5%,.13) 88%,rgba(5,5,5,.00));
  background: linear-gradient(180deg,rgba(5,5,5,.88) 28%,hsla(0,0%,96.5%,.13) 88%,rgba(5,5,5,.00));
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#050505', endColorstr='#050505', GradientType=0 );
  /* min-height: 450px; */
}


/* 
.slick-slide.slick-active{
  opacity: 1;
}

.slick-slide.slick-active.slick-center{
  opacity: 0.5;
}

.video-carousel-container > .slick-slide.slick-active{
  opacity: 0.5;
}

.video-carousel-container > .slick-slide.slick-active.slick-center{
  opacity: 1;
} */

.slick-dots{
  color: black;
  position: relative;
  display: block;
  margin: 0 auto;
  bottom: 0;
}

.slick-next:before, .slick-prev:before {
  font-size: 36px;
  line-height: 1;
  opacity: .3;
  color: #fff;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '\2022';
  text-align: center;
  opacity: .25;
  color: black;
}

ol.progtrckr {
  list-style-type: none;
  margin-bottom:0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}
ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
  width:50%;
}

.job-create-step-progress{
  padding-bottom: 60px;
  min-height: 480px
}

.job-create-step-progress .multi-step ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
  width:33.33%;
}

.unordered-list li{
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
  font-size: 16px;
  font-weight: bold;
}
.form-control{
  padding: 0.475rem .75rem;
  font-size: 14px;
}

.react-select.form-control{
  padding: 0 !important;
  font-size: 14px;
}

.share-box{
  width: 400px;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #68df96;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #00d349;
}

ol.progtrckr li:after {
  content: "\A0\A0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -4rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\39F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #68df96;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #68df96;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.generic-intro {
  display:flex;
  align-items: center;
}

.generic-intro .contentCard{
  /* width: 990px; */
  margin: auto;
  height: 540px;
}

.generic-intro h1 {
  text-align: center;
}

@media(max-width : 480px) {
  .generic-intro h1 {
    margin-top:0; 
    margin-bottom: 0;
  }
  .generic-intro .contentCard{
    height: 1040px;
    width: 100%;
  }
  .intro-video-container {
    height: 540px;
  }
}

.vjs-poster{
  position: relative;
}
video[poster]{
  object-fit: contain;
}
.vjs-poster {
  background-size: contain;
  background-position: inherit;
}

.vjs-remaining-time{
  display: block !important;
}

i{
  /* color: #264589; */
  padding: 8px 16px 8px 16px;
  vertical-align: middle;
}

.fa-copy{
  font-size: 40px;
}

.fa-facebook-square{
  color: #3C5A99;
}

.fa-twitter-square{
  color: #38A1F3;
}

.fa-linkedin{
  color:#0077B5;
}

.fa-github-square{
  color:#333;
}

.social-link{
  /* vertical-align: middle; */
}

html, body, #root, .sub-root, .bg-gradient {
  height: 100%;
}

#root{
  background-color: rgba(199,199,199,0.1);
}

.bg-light {
  background: #fff !important;
}

.job_detail_container{
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #ffffff;
  min-height:100%;
}

.link-white, .link-white:hover{
  color: #ffffff;
  text-decoration: underline;
  -webkit-text-decoration-color: #ffffff;
          text-decoration-color: #ffffff;
}

.txt-light{
  opacity: 0.5;
}

.txt-white{
  color: #FFFFFF;
}

.txt-white:hover{
  color: #FFFFFF;
}

.txt-dark{
  color: #333333;
}

.txt-primary{
  color: #5cb85c;
}

.txt-center{
  text-align: center;
}

.txt-bold{
  font-weight: 600;
}
.box-center{
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.container-center{
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.btn-step{
  width: 200px;
  font-weight: bold;
}

.btn-nav{
  height: 36px;
  /* width: 140px; */
  font-weight: bold;
  margin: auto 0;
  font-size: 14px;
}

h1{
  font-family: Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: 63px;
  margin-top: 32px;
  margin-bottom: 32px;
}

h2{
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
h3{
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
}

h4{
  opacity: 1;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
  margin-top: 16px;
  margin-bottom: 16px;
  /* color: #5cb85c; */
}

h5{
  font-size: 16px;
  font-weight: 600;
}
label{
  font-weight:bold;
}
.store_logo{
  height: 52px;
  margin-bottom: 28px;
  margin-right: 20px;
}

.circlegroup {
  padding-left:0;
  list-style: none;
  text-indent: 0px;
  counter-reset: counter;
  margin-bottom:28px;
}

.circlegroup li {
  list-style: none;
  counter-increment: counter;
  font-size: 16px;
  border-radius: 50%;
  margin-left: 10%;
  word-wrap: break-word;
  margin-bottom: 28px;
  color: #333;
  position:relative;
}

.circlegroup li::before {
  line-height: 28px;
  font-size: 16px;
  content: counter(counter);
  color: #333;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  width:32px;
  height:32px;
  text-align: center;
  margin-right: 1em;
  position:absolute; top:0; right:100%;
}

.job_code{
  background-color: #ECBA33;
  font-weight: bold;
  padding: 4px;
}
.company_logo{
  max-height: 60px;
}

.generic-intro .company_logo {
  max-height: 45px;
}

.company_name{
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.big-plus{
  font-size: 32px;
  vertical-align: middle;
  margin-left:16px;
  margin-right:16px;
}

.resume-viewer{
  width:100%; 
  height:400px;
}

.pull-bottom{
  bottom:0;
}

.video-card-carousel{
  height: 540px !important;
  /* height: 75vh; */
  /* width: 340px; */
  background-color: transparent;
  overflow: hidden;
  margin-right: auto;
  margin-left:auto;
}

.video-js.vjs-fluid {
  /* height: 540px; */
  /* min-height:100%; */
}

@media only screen 
and (max-width : 550px) {
  .video-card{
    /* max-height: 370px !important; */
    width: 340px;
  }
  .video-card-carousel{
    min-height: 540px !important;
    /* height: 600px; */
    min-width:90%;
    /* max-height: 100%; */
  }
  
  .slick-arrow{
    display:none !important;
  }
  .slick-slide{
    padding-right:0 !important;
  }
  .video-carousel-container{
    border-radius: 0;
    padding: 0;
    margin: 0 0 2rem 0;
  }
  .profile-bg{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .profile-img{
    width:64px;
    height:64px;
  }
  .store_logo{
    height:44px;
    margin-bottom:0;
    margin-right:20px;
  }
  h1{
    font-size:36px;
    line-height:52px;
    margin-top:32px;
    margin-bottom:32px;
  }
  .big-plus{
    font-size: 24px;
    margin-left:4px;
    margin-right:4px;
  }
  .circlegroup{
    padding-left:12px
  }
  .shadow-bordered{
    border: none;
  }
}

.public-DraftEditor-content{
  height: 25vh !important;
  overflow: auto;
}
hr {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,0.1);
}
.title-border{
  padding-bottom: 4px;
  border-bottom: 1px solid #eaeaea;
}

.far.fa-star{
  color: #f3ce13;
  font-size: 16px;
}
.fas.fa-star{
  color: #f3ce13;
  font-size: 16px;
}
.fs-12{
  font-size: 12px !important;
}
.fs-14{
  font-size: 14px;
}
.fs-20{
  font-size: 20px;
}
.fs-32{
  font-size: 32px;
}
.list-item{
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f1f1f1;
}
.list-item:hover{
  background-color: #eaeaea;
  cursor: pointer;
}
.list-action{
  display: block;
}
.list-item:hover .list-action{
  display: block;
}
.order-field{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding:8px;
  max-height: 40px;
}
.form-field{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding:8px;
}
.btn-icon{
  padding:0px;
  color: #aaa;
}
.btn-icon i{
  /* width: 20px; */
  font-size:20px;
  /* height: 20px; */
}
.material-icons.list{
  font-size: 20px;
}
.btn-sm, .btn-sm:hover, .btn-sm:focus{
  padding: 8px 20px;
}
.text-limit{
  justify-content: center;
  width: 56px;
}
.form-control.no-focus:focus{
  box-shadow: none;
  border-color: #ced4da;
}
.form-check-input{
  margin-top: .1rem !important;
}
.custom-dots{
  padding: 14px 10px;
  display: flex;
  height: 55%;
  width: 45% !important;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-transform: skewY(8deg);
          transform: skewY(8deg);
  border-width: 2px;
  border-style: solid;
  border-color: #eaeaea;
  -webkit-border-image: none;
          border-image: none;
  -webkit-border-image: initial;
          border-image: initial;
  border-radius: 5px;
}
.custom-dots-text{
  font-size: 12px;
}
li.slick-active .custom-dots{
  background-color: #333333;
  color:#ffffff;
  padding: 18px 14px;
  /* -webkit-transition: linear 0.5s; */
}

li.slick-active{
  margin: 0 12px 0 8px;
  /* -webkit-transition: linear 0.5s; */
}

.overflowed {
  overflow: auto;
}

.text-smaller {
  font-size: 0.8em;
}

.text-small {
  font-size: 0.9em;
}

.text-light {
  color: #b9b9b9;
}

.text-darker{
  color: #333333;
}

.section{
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-bottom: 24px;
}
.csv-data{
  max-height:400px;
  overflow: auto;
}
.custom-file{
  width:300px;
}

.onboarding-step-image {
  height: 125px;
}
.bg-grey{
  background-color: #efefef;
}
.bg-dark-grey{
  background-color: #aeaeae !important;
}
.bg-lightshade{
  background-color: #e2fdf4;
}
.bg-type{
  background-color: #f0ece5 !important
}
.bg-alice{
  background-color: aliceblue;
}

.bt{
  border-top: 1px solid #f1f1f1;
}

.bb{
  border-bottom: 1px solid #f1f1f1;
}

.br{
  border-right: 1px solid #f1f1f1;
}

.bl{
  border-left: 1px solid #f1f1f1;
}

.list-scroll{
  max-height: 200px;
  overflow: auto;
}
.list-none{
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
li a.active{
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
  color: #00b13e !important;
}

.circular {
  border-radius: 4rem;
}

.compact-profile-image {
  display: block;
  margin: auto;
}

.attachment-border {
  border: 1px solid #eee;
}

.asset-loader{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(/static/media/assets_loading.ade3da8c.gif) 
              50% 50% no-repeat rgb(249,249,249);
}

.inline h1, .inline h2, .inline h3, .inline button {
  display: inline;
  vertical-align: middle;
}

.icon-link, .icon-link:hover {
  color: black;
}

/* .modal-header {
  border-bottom: 0px;
} */

.view-switcher button{
  padding: 0.375rem 0.5rem;
}

.view-switcher button i {
  font-size: 18px;
}

.view-switcher .btn-sm, .view-switcher .btn-sm:hover, .view-switcher .btn-sm:focus {
  padding: 0.375rem 0.5rem;
}

.view-switcher-lbl {
  font-size: small;
  font-weight: normal;
}

.ant-table-thead tr > th {
  color: #000;
  font-weight: bold;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: background .3s ease;
  transition: background .3s ease;
}

.input-sm-4 {
  width: 4rem;
}

.input-float {
  float: left;
}

.flex-middle {
  display: flex;
  align-items: center;
}

.hidden-radio input {
  opacity: 0;
  position: absolute !important;
  margin: 0 !important;
  padding: 0 !important;
}

.hidden-radio i{
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  margin: 8px 8px 8px 4px;
  padding: 0;
}
.rta, .rta__textarea{
  font-size: 14px !important;
}
#interviews-tab-create{
  margin-left: auto;
  font-weight: 600;
  border-color: #00d349;
  color: #00d349;
  padding: 8px 32px;
  /* margin-bottom: 12px; */
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

#interviews-tab-create:hover {
  background-color: #00d349;
  border-color: #00d349;
  border-bottom-size: 1px !important;
  border-top-color: #00d349 !important;
  border-left-color: #00d349 !important;
  border-right-color: #00d349 !important;
}
.btn-link{
  padding: 0;
}

.full-screen {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,1); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.overlay-close{
  z-index: 1;
  position: absolute;
  /* float: right; */
  top: 32px;
  right: 32px;
  font-size: 28px;
}
.video-recorder{
  padding-left: calc((100vw - 69vh) / 2); 
  padding-right:calc((100vw - 69vh) / 2); 
  height:100vh; 
  width:100vw
}
.answer-container{
  min-height: 444px;
}
.ant-drawer-content-wrapper {
    width: 60% !important;
}

@media screen and (max-width: 768px) {
  .video-recorder{
    padding-left: 0; 
    padding-right:0; 
    height:100vh; 
    width:100vw
  }
  .answer-container{
    min-height: 340px;
  }
  .ant-drawer-content-wrapper{
    width:90% !important;
  }
}
.bottom-fix{
  margin-top: 40px;
  position: static;
  bottom:0;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 480px !important;
  overflow: scroll;
}
.panel-fullscreen {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  padding: 24px 48px;
}

.card{
  display: flex;
  flex: 1 1 300px;
  flex-direction: column;
  overflow: hidden;
  margin: 0 8px 16px;
  height: 160px;
  background: #fff 50%;
  background-size: cover;
  border-radius: 5px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}
/* .card:hover{
  -webkit-box-shadow: rgba(39,44,49,.07) 8px 28px 50px, rgba(39,44,49,.04) 1px 6px 12px;
  box-shadow: 8px 28px 50px rgba(39,44,49,.07), 1px 6px 12px rgba(39,44,49,.04);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  -webkit-transform: translate3D(0,-1px,0) scale(1.02);
  transform: translate3D(0,-1px,0) scale(1.02);
} */
.card:hover{
  cursor: pointer;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  -webkit-transform: translate3D(0,-1px,0) scale(1.02);
  transform: translate3D(0,-1px,0) scale(1.02);
}
.shadow{
  box-shadow: 8px 14px 38px rgba(39,44,49,.06), 1px 3px 8px rgba(39,44,49,.03);
}
.row-star li{
  width: 30px !important;
  font-size: 20px !important;
  margin-right: 0 !important;
}
.small-editor .ql-container{
  height: 120px !important;
}

.answer-editor .ql-container{
  contain: size;
  min-height: 643px !important;
}

.question-desc-container{
  height: 642px !important;
  overflow-y: scroll;
}

.review-drawer .ant-drawer-header{
  position: fixed;
  width: 100%;
  background: white;
  z-index: 10;
}

.review-drawer .ant-drawer-body{
  padding-top: 52px;
}

.fh-scroll-container{
  max-height: 460px;
  overflow-y: auto;
}
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
 
.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
 
.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
 
.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
 
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
 
.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.q-description-img{
  max-width: 420px !important;
}

.img-full-width{
  max-width: 100% !important;
}

.transcripts-well {
  padding: 0;
  background: #f3f3f3;
  max-height: 140px;
  overflow-x: scroll;
}

.transcripts-well i {
  float: left;
}

.transcripts-well .response-transcripts {
  padding: 10px 20px;
  font-size: 14px;
}

.video_container {
  position: relative;
}

.video_container img.poster {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 10;
}

.video_container .video-js {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 110;
}

.template-row:hover{
  box-shadow: 0 3px 12px 0 rgba(0,0,0,.12);
  -webkit-transform: translate3d(0,-1px,0);
  transform: translate3d(0,-1px,0);
}
.template-row:hover .btn{
  display: block;
}
.template-row .btn{
  display: none;
}
.react-datepicker-wrapper{
  width: 100%;
}
